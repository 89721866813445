
.table {
  font-family: Arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.th, .td {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.tr:nth-child(even) {
  background-color: #dddddd;
}
