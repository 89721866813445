
  .form{
    background-color: white;
    padding: 20px; /* Padding inside the form */
    box-shadow: 0 4px 8px rgba(8, 8, 8, 0.1); /* Subtle shadow */

  }
  h1 {
    color:black;
    font-size: large;
    text-align: center;
    text-shadow: 1px;
    padding: 5px 0;
    text-underline-offset: 1px;
    font-weight: 500;
    
  }  
  .headerLogo{
    display: flex;
    justify-content: space-between;
   
  }

  .address{
    text-align: center;
    border-bottom: 1px solid #000;
  }
.CmsfLogo{
  height: 50px;
}
.Golden50{
  height: 60px;
}
.Mujib100{
height: 60px;
}



.GategoryName {
  font-size: 18px;
  padding: 8px;
  border-radius: 4px;
  background: linear-gradient(to right, #3498db, #cbb9f1); /* Gradient from deep blue to white */
  color: #fff;
}

.GategoryName2 {
  font-size: 18px;
  padding: 8px;
  border-radius: 4px;
  /* background: linear-gradient(to right, #3498db, #cbb9f1);  */
  color: #080808;
}

.msg{
  text-align: right !important;
  font-size: 8px;
  align-items: right;
}

.msgDiv{
  margin-left: 600px;
}


.fileListContainer {
  margin-top: 20px; /* Adjust the top margin as needed */
}

.fileListHeader {
  font-size: 16px;
  font-weight: bold;
}

.fileList {
  list-style-type: decimal;
  margin-left: 20px; /* Adjust the left margin as needed */
}

.fileListItem {
  margin-bottom: 10px; /* Adjust the bottom margin for each list item */
}